.footer{
  .footerLayout {
    display: flow-root;
    justify-content: space-between;
    padding: 0.8% 4vw;
    // width: 92vw;
    background: #FBF8F3;
    ul{
      float: left;
      .sections{
        text-align: left;
        font-size: 13px;
        display: block;
        font-weight: 300;
        margin: 6px 6em;
        padding: 0.5em 0;
        cursor: pointer;
        a{
          text-decoration: none;
          color: black;
        }
        .social-media{
          padding: 0 2em 0 0;
        }
      }
      .firstHeader{
        font-weight: 600;
      }      
    }
  }
  .bottomNav{
    font-size: 1rem;
    background: #C28D6C;
    color: white;
    text-align: center;
    padding: 3vh;
    font-weight: 300;
  }
  @media screen and (max-width: 600px) {
    .sections{
      margin: unset !important;
    }
  }
}