@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "./root_variable.scss";

:root {
  --primary-color  : #C28D6C;
  --reset-tag-color: #989898;
  --card-font-color: #828282;
  --universal-color: black;
  --button         : #B17E5E;
}

body {
  margin     : 0;
  font-family: Roboto Slab;
  color      : $universalColor;
}

.fieldtext {
  font-size: 12px;
  display  : block;
}

.filter_title_text {
  font-size: 14px !important;
}

.horizontal-scroll {
  overflow   : scroll !important;
  white-space: nowrap !important;
}

.buttondark {
  font-weight     : 400;
  background-color: $button;
  color           : white;
  font-size       : 15px;
  padding         : 12px 30%;
  display         : inline-block;
  margin-top      : 16px;
  cursor          : pointer;
}

.f200 {
  font-weight: 200;
}

.remove-shadow {
  box-shadow: none !important;
}

.button {
  white-space: nowrap;
}

// STEPPER
.stepper {
  .marginauto {
    margin: auto;
  }

  .breadcrumb {
    list-style: none;
    overflow  : hidden;
    margin    : 40px 0;
    padding   : 0;
  }

  .breadcrumb li {
    float : left;
    width : 33%;
    border: black 1px solid;
  }

  @media screen and (max-width: 600px) {
    .breadcrumb li {
      width     : -webkit-fill-available;
      // display: inline;
    }
  }

  .breadcrumb li span {
    cursor         : pointer;
    color          : black;
    text-decoration: none;
    padding        : 10px 0 10px 45px;
    background     : transparent;
    position       : relative;
    display        : grid;
    float          : left;
    height         : 3em;
    width          : 80%;
  }

  .breadcrumb li:first-child a {
    padding-left: 10px;
  }

  .active-step {
    border: none !important;

    span {
      background-color: black !important;
      color           : white !important;
    }

    span:before {
      border-left: 30px solid white !important;
    }

    span:before {
      border-left: 30px solid black !important;
    }

    span:before {
      content      : " ";
      display      : block;
      width        : 0;
      height       : 0;
      border-top   : 33px solid transparent;
      border-bottom: 33px solid transparent;
      border-left  : 30px solid black;
      position     : absolute;
      top          : 50%;
      margin-top   : -33px;
      left         : 100%;
      z-index      : 0;
    }
  }
}

.metal {
  .metalthing {
    label {
      margin-right: 1em;

      input[type="radio"] {
        display: none;

        &:checked {
          +.box1 {
            width: 15px;

            span {
              color: white;
            }
          }
        }
      }

      .box1 {
        height     : 20px;
        width      : 0px;
        padding    : 5px 15px !important;
        transition : all 250ms ease;
        will-change: transition;
        display    : inline-block;
        text-align : center;
        cursor     : pointer;

        span {
          color: $universalColor;
        }
      }

      .border {
        border: 1px solid black;
      }
    }
  }

  .taglist {
    padding: 0 !important;

    .reset_tag {
      padding     : 5px 15px !important;
      margin-right: 1em;
      cursor      : pointer;
    }
  }
}

// pagination start have to put it in pagination componenet used every hwere
.pagination-ui {
  display: inline-block !important;
  padding: 0 !important;
  margin : 2em 0 !important;

  li {
    display        : inline !important;
    list-style-type: none;

    a {
      border         : #c5c5c5 solid 1px;
      color          : black !important;
      float          : left !important;
      padding        : 8px 16px !important;
      text-decoration: none !important;
    }
  }

  .active {
    a {
      background-color: $primaryColor;
    }
  }

  a:focus {
    border: #c5c5c5 solid 1px;
  }
}

// pagination end

// global column start
.column {
  float        : left;
  padding      : 0 1%;
  margin-bottom: 20px;
  font-weight  : 400;
  font-size    : 12px;

  .card {
    font-size     : 14px;
    cursor        : pointer;
    color         : $cardFontColor;
    text-transform: uppercase;
    box-shadow    : 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    padding       : 16px 16px 16px 16px;
    min-width     : 13vw;

    img {
      width     : 100%;
      // height    : 30vh;
      min-height: 15em;
      object-fit: contain;
    }

    .footer {
      margin-top: 0 !important;

      .float_left {
        font-weight: 400 !important;
      }

      .float_right {
        text-align : right;
        color      : black;
        font-weight: 900 !important;
      }
    }
  }
}

.column30 {
  width    : 30% !important;
  min-width: 22vw;
}

.column50 {
  width: 45% !important;
  min-width: 22vw;
}

.quick-column-fix {
  width: 18% !important;
}

.img-responsive {
  width     : 400px;
  max-height: 100%;
}

@media screen and (max-width: 600px) {
  .img-responsive {
    width        : 380px;
    // max-height: unset;
  }

  .filter_button {
    float : right;
    margin: 0;

    .buttondark {
      padding: 5px 10px;
      margin : 0;
    }
  }

  .column {
    width        : 100%;
    display      : block;
    margin-bottom: 20px;
  }

  .column30 {
    width: 100% !important;
  }

  .column50 {
    width: 90% !important;
  }

  .quick-column-fix {
    width: 100% !important;
  }

  .hide_mobile {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .HideNonMobile {
    display: none !important;
  }
}

::-webkit-scrollbar {
  width : 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 50px;
  border-radius        : 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 50px;
  border-radius        : 50px;
  background           : $primaryColor;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: $primaryColor;
}

// global column end

.filter-text {
  border       : 0;
  outline      : 0;
  background   : transparent;
  border-bottom: 1px solid #eeeeee;
  margin       : 0 16px 0 0;
}

.filter-text::placeholder {
  color: #c1c1c1;
}

.filter-text:focus {
  border-bottom: 1px solid $primaryColor;
}

.float_leftu {
  float: left;
}

.float_rightu {
  float: right;
}