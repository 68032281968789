.bodyLayout {
    padding: 15vh 0 0 0;
    min-height: 90vh;
    max-width: 100vw;
    margin: auto;
    // background-color: whitesmoke;
  }
 
@media screen and (max-width: 600px) {
  .bodyLayout {
    padding: 10vh 0 0 0;
  }
}  

.logoanimation{
  display: grid;
  grid-template-columns: repeat(2,auto);
  justify-content: center;
  align-items: center;
  .jinagnalogoan{
    object-fit: contain;
    height: 80px;
    width: 40px;
  }
  .jinagnatext{
    display: grid;
    margin-left: 1em;
    color: #905c46;
    font-family: roborto;
  }
}