@import "./../../../root_variable.scss";

.top-header {
  z-index: 10;
  background-color: white;
  height: 5vh;
  position: fixed;
  width: 92vw;
  padding: 0% 4vw;
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b17e5e;
  .border {
    border: 1px solid $primaryColor;
    padding: 3px 5px;
  }
  ul {
    font-weight: 400;
    margin: 0;
    li {
      img {
        height: 15px;
        width: 32px;
      }
      span,
      a {
        text-decoration: none;
        color: black;
        font-size: 0.8em;
        font-family: Montserrat;
      }
    }
    .close-fix {
      position: absolute;
      left: 85vw;
      padding-top: 15px;
      display: none;
    }
  }
  .nav-options {
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 50px;
    list-style-type: none;
  }

  .option :hover {
    color: #905c46;
  }

  li.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content span:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
.hide {
  display: none;
}
.header-margin-top {
  margin-top: 5vh;
}
.header {
  z-index: 1;
  background-color: #fbf8f3;
  height: 11vh;
  position: fixed;
  width: 92vw;
  padding: 0% 4vw;
  // box-shadow: 0 -1px 8px 0 grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b17e5e;
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      // flex: 1;
      .logo {
        max-height: 52px;
        max-width: 100%;
      }
    }
  }
  ul {
    font-weight: 500;
    li {
      a {
        text-decoration: none;
        color: black;
        font-weight: 500;
      }
    }
    .close-fix {
      position: absolute;
      left: 85vw;
      padding-top: 15px;
      display: none;
    }
  }
  .nav-options {
    padding-left: 25px;
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 50px;
    list-style-type: none;
  }
  .mobile-option {
    display: none;
  }

  .option :hover {
    color: #905c46;
  }
  .mobile-menu {
    display: none;
  }
  .mb-100 {
    display: none;
  }
}
.hidenonmobile_withborder {
  display: none;
  border: 1px solid $primaryColor;
}
.top-header {
  z-index: 100000;
  height: 5vh;
}

@media (max-width: 320px) {
  .header {
    .logo-nav {
      width: 50%;
    }
  }
}
@media (max-width: 648px) {
  .option :hover {
    color: #905c46;
  }

  li.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content span:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdown-content {
    display: flex;
  }
  .hidenonmobile_withborder {
    display: block;
    padding: 0;
    margin-right: 3vw;
    li {
      padding: 3px 9px !important;
    }
  }
  .top-header {
    display: none !important;
  }
  .header {
    margin-top: 0;
    border-bottom: none;
    .nav-options {
      display: flex;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: -16px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
      padding-left: 0;
    }
    .nav-options.active {
      background: #905c46;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
    }
    .close-fix {
      display: block !important;
    }
    ul {
      li {
        font-size: 1.1em;
        a {
          font-weight: 400;
          color: white;
        }
      }
    }
    .menu-icon {
      width: 45px;
      height: 45px;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4vh;
      padding: 15px 15px;
    }
    .mb-100 {
      margin-bottom: 100px;
      display: block;
    }
  }
  .option :hover {
    color: black;
  }
  .mobile-menu {
    display: block !important;
  }
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.my-float {
  height: 3em;
  width: 3em;
}
